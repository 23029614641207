import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import CTA from "../../components/cta"

export default () => (
  <Layout>

    <SEO title="Solution for tour resellers and online travel agencies (OTA's)"
        description="The Getlocal solution works for tour operators, suppliers and guides that want to sell their services online"
        pathname="/pricing/resellers-ota/"
    />
        
    <Hero 
         headerText="Resellers / OTA's" 
         subHeaderText="Solution for resellers and online travel agencies"
            /> 

   

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>Solution built for online travel agencies</h2>
            
            <p>Creating a strong sales channel is expensive and time consuming. By using Getlocal you get a ready made solution out of the box that will solve most if not all of your challenges.</p>
            <CTA />
        </div>

       
        
      </div>
    </section>
    <section className="section content-pages">
    <div className="content columns">
        <div className="column is-6">
            <h2>Save time with a proven solution</h2>
            <p>Get started quickly and go to market in record time with a proven solution used around the world.
            </p>
        </div>
        
        <div className="column is-6">
            <h2>Reduce cost and simplify operations</h2>
            <p>Why spend tons of money building a solution that already exists, focus on marketing and service instead.</p>
                
        </div>
        </div>
    </section>

  </Layout>
)
